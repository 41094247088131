<template>
  <div>
    <Row class="m-b-10">
      <i-col span="24">
        <i-button size="small" type="success" @click="handleAddInventory">新增商品</i-button>
      </i-col>
    </Row>

    <Table size="small" stripe :data="list" :loading="tableLoading" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator show-sizer :current="query.pageNumber"
        :page-size-opts="pagesizeSetting" @on-change="handlePageChange" @on-page-size-change='changePageSize'></Page>
    </div>

    <ModifyInventory ref="addInventory" @on-success="handleAddInventorySuccess"/>
  </div>
</template>

<script>
import ModifyInventory from './ModifyInventory'
import { getInventory } from '@/api/product/cloudinventory2'

export default {
  components: {
    ModifyInventory
  },
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 20,
        publisherId: this.$store.getters.token.userInfo.publisherId
      },

      list: [],
      total: 0,
      pagesizeSetting: [20, 50, 100, 200],

      tableLoading: false,
      tableColumns: [
        { title: '商品名称', key: 'name' },
        { title: '等级', key: 'levelName' },
        { title: '资源类型', key: 'resourceTypeName' },
        { title: '最近一次更新时间', key: 'updateTime' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const buttons = [
              h('a', {
                on: {
                  click: () => {
                    this.handleSettingInventory(params.row)
                  }
                }
              }, '商品设置')
            ]

            return h('div', buttons)
          }
        }
      ]
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      this.tableLoading = true

      getInventory(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.records.map(item => {
            item.levelName = this.getInventoryLevelName(item.level)
            return item
          })
          this.total = res.total
          this.query.pageNumber = res.current
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
        this.tableLoading = false
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    changePageSize (pageSize) {
      this.query.pageSize = pageSize
      this.query.pageNumber = 1
      this.initData()
    },
    handleAddInventory () {
      this.$refs.addInventory.showModal()
    },
    handleAddInventorySuccess () {
      this.handleSearch()
    },
    handleSettingInventory (params) {
      this.$emit('on-detail', params.id)
    },
    getInventoryLevelName (level) {
      let levelName = ''
      switch (level) {
        case 1:
          levelName = 'A'
          break
        case 2:
          levelName = 'A+'
          break
        case 3:
          levelName = 'A++'
          break
        case 4:
          levelName = 'S'
          break
        case 5:
          levelName = 'S+'
          break
        case 6:
          levelName = 'S++'
          break
        default:
          break
      }
      return levelName
    }
  }
}
</script>
